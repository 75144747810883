/** @format */

import React, { useState, useEffect } from "react";
import { getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import {
    StyledImage,
    StyledGallerySection,
    StyledImagesWrapper,
    StyledViewerImage,
    StyledGalleryOpenWrapper,
    StyledOpenImage,
    StyledCloseModal,
} from "./StyledGallery";

import Left from "../../images/left.svg";
import Right from "../../images/right.svg";

const GallerySection = ({ data }) => {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [actualImage, setActualImage] = useState(false);
    const [imageLength, setImageLength] = useState();

    const handleOpenCarousel = (e) => {
        e.preventDefault();
        setViewerIsOpen(true);
        setActualImage(parseInt(e.target.dataset.index));
    };

    const handleCloseOpenCarousel = () => {
        setViewerIsOpen(false);
    };

    const handlePrev = () => {
        setActualImage(
            actualImage <= 0 ? data.node.zdjecie.length - 1 : actualImage - 1
        );
    };
    const handleNext = () => {
        if(actualImage >= 0 && actualImage !== data.node.zdjecie.length - 1){
            setActualImage(actualImage + 1);
        } else {
            setActualImage(0);
        }
    };

    useEffect(() => {
        setImageLength(data.node.zdjecie.length - 1);
    }, [viewerIsOpen])

    return (
        <StyledGallerySection>
            <p>{data.node.tytulSekcji}</p>
            <StyledImagesWrapper>
                {data.node.zdjecie.map(({ zdjecie }, index) => (
                    <div
                        key={index}
                        onClick={handleOpenCarousel}
                        data-index={index}
                    >
                        <StyledImage
                            image={getImage(zdjecie)}
                            alt=""
                            data-index={index}
                        />
                    </div>
                ))}
            </StyledImagesWrapper>
            {viewerIsOpen && (
                <StyledViewerImage>
                    <StyledCloseModal onClick={handleCloseOpenCarousel} />
                    <StyledGalleryOpenWrapper>
                        {
                            imageLength > 0 ? 
                                <motion.div
                                    whileTap={{ scale: 1.2 }}
                                    onClick={handlePrev}
                                >
                                    <Left />
                                </motion.div> 
                            : <div></div>
                        }
                        <motion.div>
                            {data.node.zdjecie
                                .filter((image, index) => index === actualImage)
                                .map(({ zdjecie }) => (
                                    <StyledOpenImage
                                        image={getImage(zdjecie)}
                                        alt=""
                                    />
                                ))}
                        </motion.div>
                        {
                            imageLength > 0 ? 
                                <motion.div
                                    whileTap={{ scale: 1.2 }}
                                    onClick={handleNext}
                                >
                                    <Right />
                                </motion.div>
                            : <div></div>
                        }
                    </StyledGalleryOpenWrapper>
                </StyledViewerImage>
            )}
        </StyledGallerySection>
    );
};

export default GallerySection;
