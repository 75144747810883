/** @format */

import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledGallery = styled.section`
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 20px;
`;

export const StyledGallerySection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 160.7%;
        position: relative;
        margin-bottom: 12px;
        &::after{
            content: '';
            display: block;
            left: -5px;
            bottom: 4px;
            height: 3px;
            width: 40px;
            border-radius: 25px;
            background-color: #FF4D00;
        }
    }

    @media only screen and (max-width: 431px){
        padding-top: 20px;
        > p{
            font-size: 26px;
        }
    }
`;

export const StyledImagesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    > div {
        width: 32%;
        min-width: 300px;
    }
    @media only screen and (max-width: 962px) {
        justify-content: space-evenly;
        > div {
            width: 48%;
        }
    }
    @media only screen and (max-width: 751px) {
        > div {
            width: 80%;
        }
    }
    @media only screen and (max-width: 483px) {
        > div {
            width: 100%;
        }
    }
`;

export const StyledImage = styled(GatsbyImage)`
    width: 100%;
    height: 300px;
    cursor: pointer;
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.25));
    border-radius: 22px;
    object-fit: contain;
`;

export const StyledViewerImage = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledGalleryOpenWrapper = styled.div`
    width: 90%;
    max-width: 1040px;
    height: 100%;
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    position: relative;
    > div {
        &:nth-child(1),
        &:nth-child(3) {
            cursor: pointer;
        }
        &:nth-child(2){
            width: auto;
            max-width: 90%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 939px){
        gap: 10px;
        width: 95%;
    }
`;

export const StyledOpenImage = styled(GatsbyImage)`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

export const StyledCloseModal = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border-radius: 50%;
    transform: rotate(45deg);
    z-index: 101;
    &::after {
        content: "";
        width: 4px;
        height: 70%;
        background-color: ${({ isblack }) => isblack ? "#000" : "#fff"};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 25px;
    }
    &::before {
        content: "";
        width: 70%;
        height: 4px;
        background-color: ${({ isblack }) => isblack ? "#000" : "#fff"};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 25px;
    }
`;
