/** @format */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import GlobalLayout from "../components/layouts/GlobalLayout";
import SiteHeader from "../components/SiteHeader/SiteHeader";
import Footer from "../components/Footer/Footer";
import GallerySection from "../components/Gallery/GallerySection";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

import { StyledGallery } from "../components/Gallery/StyledGallery";

const Galeria = () => {
    const data = useStaticQuery(graphql`
        query Galeria {
            allDatoCmsSekcjeDoGalerii {
                edges {
                    node {
                        tytulSekcji
                        zdjecie {
                            zdjecie {
                                gatsbyImageData(placeholder: TRACED_SVG)
                                alt
                            }
                        }
                    }
                }
            }
        }
    `);

    return (
        <GlobalLayout>
            <HelmetTemplate title="Galeria" desc="Zdjęcia z naszego obiektu" />
            <SiteHeader title="Galeria" />
            <StyledGallery>
                {data.allDatoCmsSekcjeDoGalerii.edges.map((sekcja, index) => (
                    <GallerySection
                        key={index}
                        data={sekcja}
                    />
                ))}
            </StyledGallery>
            <Footer />
        </GlobalLayout>
    );
};

export default Galeria;
